export const INITIAL_SNACKBAR = {
  isOpen: false,
  severity: "success",
  message: "",
  autoHideDuration: null,
};

export const AXIMSOFT_FRONTEND_DEVELOPMENT = [
  "Led the team in building the entire front-end React application and bound the JSON data to the front-end React application from APIs and Websocket using Redux, Redux-Sagas, and successfully fused the React application into another Ember JS application.",
  "Create and customize interactive dashboards, editors, tables, calendars, forms, and charts and generate reports using libraries like DraftJS, Highcharts, Material-UI, and React-Bootstrap resulting in a 25% increase in user engagement and improved data visualization.",
  "Took ownership of the products and streamlined internal tools and libraries to reduce code duplication and technical debt by creating reusable components and custom hooks, improving development efficiency by 35%.",
  "Consistently conducted thorough testing and troubleshooting with Jest, effectively addressing and debugging bugs, improving application stability, and significantly reducing downtime by up to 10%.",
  "Conducted code reviews, and code documentation and led a team of three developers, providing mentorship on best practices and coding standards, improving code readability, scalability, and modularity.",
  "Contributed to the success of the team beyond the scope of my role, hence awarded ”Team Player of the Month” thrice.",
];

export const AXIMSOFT_BACKEND_DEVELOPMENT = [
  "Performed CRUD operation and implemented 20+ new RESTful APIs using Node JS, Express JS, GraphQL, and PostgreSQL, including the successful integration of GraphQL, resulting in a 40% reduction in API requests and a 30% improvement in response time.",
  "Infused OpenAI with customized prompts for each user based on their previous posts, upgrading the application to a smarter system.",
  "Automated AI generated social media posts on various social media platforms using Cron and posted them using Social Media APIs and increased the user’s social media presence by 25%.",
  "Successfully implemented GraphQL API in a large-scale application at Aximosoft, reducing the number of API requests by 40% and improving response time by 30%.",
];

export const FASHION_MINISAT = [
  "Achieved 94% accuracy for the CNN model by performing hyperparameter tuning to determine the optimal neural network architecture.",
  "Identified mystery labels in the dataset by leveraging encodings from the intermediate layer of a CNN model by applying dimensionality reduction with PCA and employing unsupervised learning algorithms such as K-means and DBSCAN for clustering.",
  "Analyzed feature extraction through dimensionality reduction using PCA and Autoencoder and determined that Autoencoder generated the most effective representation based on improved classification test accuracy with KNN."
];

export const FEATURE_MATCHING = [
  "Conducted a comparison study of feature matching algorithms (SIFT, SURF, ORB and AKAZE) for Pancreas CT Scan dataset.",
  "Enhanced the matching accuracy of the feature matching algorithms by using RANSAC to remove outliers.",
  "Implemented an ensemble of SURF and ORB to construct an improved 3D point cloud construction of the Pancreas."
];


export const CRIBBO = [
  "Architected backend infrastructure with the development team, encompassing the design of servers, MongoDB databases, and microservices using Moleculer JS framework, to establish a solid foundation for enhanced scalability and performance of the application.",
  "Expertly integrated third-party APIs, such as Equifax to retrieve user credit reports and convert them into a readable PDF format, and Stripe to implement a seamless payment system, resulting in enhanced application capabilities.",
  "Leveraged Nodemailer and SendGrid for efficient email communication and Firebase Cloud Messaging for real-time notifications, resulting in a 20% increase in user engagement, and built robust chat APIs to facilitate seamless communication between users.",
  "Documented the code using Swagger, enabling effective communication among team members by providing an interactive interface for exploring and understanding the API.",
  // "Implemented CI/CD pipelines using GitHub, Travis, Docker, and AWS, resulting in a remarkable 50% reduction in deployment time, faster code delivery, and a significant 40% decrease in deployment errors."
];

